import React from "react";

export default function ClientListItem({ clients }) {
  var clientsList = clients.map((client) => (
    <li className="card-item" key={client.id}>
      <div className="card">
        <div className="card-image">
          <img src={client.image} alt={client.alt}/>
        </div>
        <div className="card-content">{client.name}</div>
      </div>
    </li>
  ));
  return <ul className="cards">{clientsList}</ul>;
}
