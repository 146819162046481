import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ClientListItem from "../components/ClientListItem";
import clientsHeroImage from "../../static/img/clients-hero-image.png";

import advertisement from "../../static/img/clients/1advertisement.png";
import automotive from "../../static/img/clients/2automotive.png";
import battery from "../../static/img/clients/3battery.png";
import it from "../../static/img/clients/4it.png";
import fmcg from "../../static/img/clients/5fmcg.png";
import ewaste from "../../static/img/clients/6ewaste.png";
import hr from "../../static/img/clients/7hr.png";
import food from "../../static/img/clients/8food.png";
import computing from "../../static/img/clients/9computing.png";
import dataservices from "../../static/img/clients/10dataservices.png";
import consultancy from "../../static/img/clients/11consultancy.png";
import mobility from "../../static/img/clients/12mobility.png";
import insurance from "../../static/img/clients/13insurance.png";
import medical from "../../static/img/clients/14medical.png";
import paints from "../../static/img/clients/15paints.png";
import lean from "../../static/img/clients/16leads.png";
import publishing from "../../static/img/clients/17publish.png";
import retail from "../../static/img/clients/18retail.png";
import footwear from "../../static/img/clients/19footwear.png";
import lighting from "../../static/img/clients/20automotive.png";

const clients = [
  {
    id: 1,
    image: advertisement,
    name: "Advertisement/Marketing",
    alt: "advertisment",
  },
  {
    id: 2,
    image: automotive,
    name: "Automotive & Industrial Component Manufacturing",
    alt: "automotive",
  },
  { id: 3, image: battery, name: "Battery Manufacturing", alt: "battery" },
  { id: 4, image: it, name: "Information Technology", alt: "it" },
  { id: 5, image: fmcg, name: "FMCG", alt: "fmcg" },
  { id: 6, image: ewaste, name: "e-Waste Recycling", alt: "ewaste" },
  { id: 7, image: hr, name: "Human Resources and Training", alt: "hr" },
  { id: 8, image: food, name: "Food Processing", alt: "food" },
  { id: 9, image: computing, name: "Computing Technology", alt: "computing" },
  { id: 10, image: dataservices, name: "Data Services", alt: "dataservices" },
  { id: 11, image: consultancy, name: "Consultancy", alt: "consultancy" },
  { id: 12, image: mobility, name: "Mobility Technology", alt: "mobility" },
  {
    id: 13,
    image: insurance,
    name: "Insurance and Mortgage",
    alt: "insurance",
  },
  { id: 14, image: medical, name: "Medical Diagnostics", alt: "medical" },
  { id: 15, image: paints, name: "Paints and Coatings", alt: "paints" },
  { id: 16, image: lean, name: "Lean Manufacturing SaaS", alt: "lean" },
  { id: 17, image: publishing, name: "Publishing", alt: "publishing" },
  { id: 18, image: retail, name: "Retail", alt: "retail" },
  { id: 19, image: footwear, name: "Footwear Manufacturer", alt: "footwear" },
  { id: 20, image: lighting, name: "Automotive Lighting", alt: "lighting" },
];

export const ClientsPageTemplate = ({ sectionOne, sectionTwo }) => {
  const [height, setHeight] = useState(
    typeof window !== "undefined" && window.innerHeight
  );

  typeof window !== "undefined" &&
    window.addEventListener("resize", function () {
      setHeight(typeof window !== "undefined" && window.innerHeight);
    });

  return (
    <div className="container">
      <section
        className="clients-hero-section"
        style={{ "--vh": `${height}px` }}
      >
        <div className="clients-hero-col-1">
          <div className="clients-page-title-mobile">
            {sectionOne.sectionTitle}
          </div>
        </div>
        <div className="clients-hero-col-2">
          <img src={clientsHeroImage} alt={"hero"} />
        </div>
        <div className="clients-hero-col-3">
          <div className="clients-page-title-desktop">
            {sectionOne.sectionTitle}
          </div>
          <p className="text-content">
            At <span className="littler">Little</span>
            <span className="r">r</span>,{" "}
            {sectionOne.sectionDescription.paragraph}
          </p>
        </div>
      </section>

      <section className="client-section">
        <div className="section-title">{sectionTwo.sectionHeadline}</div>
        <ClientListItem clients={clients} />
      </section>
    </div>
  );
};

const ClientsPage = ({ data }) => {
  const { frontmatter } = data.markdown;

  return (
    <Layout>
      <ClientsPageTemplate
        sectionOne={frontmatter.sectionOne}
        sectionTwo={frontmatter.sectionTwo}
      />
    </Layout>
  );
};

ClientsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ClientsPage;

export const pageQuery = graphql`
  query ClientsPageDataTemplate {
    markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "clients-page" } }
    ) {
      frontmatter {
        sectionOne {
          sectionTitle
          sectionDescription {
            paragraph
          }
        }
        sectionTwo {
          sectionHeadline
        }
      }
    }
  }
`;
